import React from "react";
import { UncontrolledCarousel } from "reactstrap";

//import image3 from "../../.././assets/images/thumbs/04.png";

const max = 500;

const items = [
  {
    id: Math.floor(Math.random() * max + 1),
    src:
      " /assets/images/thumb2/1 (" +
      Math.floor(Math.random() * max + 1) +
      ").jpg",
    altText: "Gallery Randomizer",
    caption: "",
    interval: "30",
  },
  {
    id: Math.floor(Math.random() * max + 1),
    src:
      " /assets/images/thumb2/1 (" +
      Math.floor(Math.random() * max + 1) +
      ").jpg",
    altText: "Gallery Randomizer",

    interval: "30",
    caption: "",
  },
  {
    id: Math.floor(Math.random() * max + 1),
    src:
      " /assets/images/thumb2/1 (" +
      Math.floor(Math.random() * max + 1) +
      ").jpg",
    altText: "Gallery Randomizer",

    interval: "30",
    caption: "",
  },
  {
    id: Math.floor(Math.random() * max + 1),
    src:
      " /assets/images/thumb2/1 (" +
      Math.floor(Math.random() * max + 1) +
      ").jpg",
    altText: "Gallery Randomizer",

    interval: "30",
    caption: "",
  },
  {
    id: Math.floor(Math.random() * max + 1),
    src:
      " /assets/images/thumb2/1 (" +
      Math.floor(Math.random() * max + 1) +
      ").jpg",
    altText: "Gallery Randomizer",

    interval: "30",
    caption: "",
  },
  {
    id: Math.floor(Math.random() * max + 1),
    src:
      " /assets/images/thumb2/1 (" +
      Math.floor(Math.random() * max + 1) +
      ").jpg",
    altText: "Gallery Randomizer",

    interval: "30",
    caption: "",
  },
  {
    id: Math.floor(Math.random() * max + 1),
    src:
      " /assets/images/thumb2/1 (" +
      Math.floor(Math.random() * max + 1) +
      ").jpg",
    altText: "Gallery Randomizer",

    interval: "30",
    caption: "",
  },
  {
    id: Math.floor(Math.random() * max + 1),
    src:
      " /assets/images/thumb2/1 (" +
      Math.floor(Math.random() * max + 1) +
      ").jpg",
    altText: "Gallery Randomizer",

    interval: "30",
    caption: "",
  },
  {
    id: Math.floor(Math.random() * max + 1),
    src:
      " /assets/images/thumb2/1 (" +
      Math.floor(Math.random() * max + 1) +
      ").jpg",
    altText: "Gallery Randomizer",

    interval: "30",
    caption: "",
  },
  {
    id: Math.floor(Math.random() * max + 1),
    src:
      " /assets/images/thumb2/1 (" +
      Math.floor(Math.random() * max + 1) +
      ").jpg",
    altText: "Gallery Randomizer",

    interval: "30",
    caption: "",
  },
  {
    id: Math.floor(Math.random() * max + 1),
    src:
      " /assets/images/thumb2/1 (" +
      Math.floor(Math.random() * max + 1) +
      ").jpg",
    altText: "Gallery Randomizer",

    interval: "30",
    caption: "",
  },
  {
    id: Math.floor(Math.random() * max + 1),
    src:
      " /assets/images/thumb2/1 (" +
      Math.floor(Math.random() * max + 1) +
      ").jpg",
    altText: "Gallery Randomizer",

    interval: "30",
    caption: "",
  },
  {
    id: Math.floor(Math.random() * max + 1),
    src:
      " /assets/images/thumb2/1 (" +
      Math.floor(Math.random() * max + 1) +
      ").jpg",
    altText: "Gallery Randomizer",

    interval: "30",
    caption: "",
  },
  {
    id: Math.floor(Math.random() * max + 1),
    src:
      " /assets/images/thumb2/1 (" +
      Math.floor(Math.random() * max + 1) +
      ").jpg",
    altText: "Gallery Randomizer",

    interval: "30",
    caption: "",
  },
  {
    id: Math.floor(Math.random() * max + 1),
    src:
      " /assets/images/thumb2/1 (" +
      Math.floor(Math.random() * max + 1) +
      ").jpg",
    altText: "Gallery Randomizer",

    interval: "30",
    caption: "",
  },
  {
    id: Math.floor(Math.random() * max + 1),
    src:
      " /assets/images/thumb2/1 (" +
      Math.floor(Math.random() * max + 1) +
      ").jpg",
    altText: "Gallery Randomizer",

    interval: "30",
    caption: "",
  },
  {
    id: Math.floor(Math.random() * max + 1),
    src:
      " /assets/images/thumb2/1 (" +
      Math.floor(Math.random() * max + 1) +
      ").jpg",
    altText: "Gallery Randomizer",

    interval: "30",
    caption: "",
  },
  {
    id: Math.floor(Math.random() * max + 1),
    src:
      " /assets/images/thumb2/1 (" +
      Math.floor(Math.random() * max + 1) +
      ").jpg",
    altText: "Gallery Randomizer",

    interval: "30",
    caption: "",
  },
  {
    id: Math.floor(Math.random() * max + 1),
    src:
      " /assets/images/thumb2/1 (" +
      Math.floor(Math.random() * max + 1) +
      ").jpg",
    altText: "Gallery Randomizer",

    interval: "30",
    caption: "",
  },
  {
    id: Math.floor(Math.random() * max + 1),
    src:
      " /assets/images/thumb2/1 (" +
      Math.floor(Math.random() * max + 1) +
      ").jpg",
    altText: "Gallery Randomizer",

    interval: "30",
    caption: "",
  },
  {
    id: Math.floor(Math.random() * max + 1),
    src:
      " /assets/images/thumb2/1 (" +
      Math.floor(Math.random() * max + 1) +
      ").jpg",
    altText: "Gallery Randomizer",

    interval: "30",
    caption: "",
  },
  {
    id: Math.floor(Math.random() * max + 1),
    src:
      " /assets/images/thumb2/1 (" +
      Math.floor(Math.random() * max + 1) +
      ").jpg",
    altText: "Gallery Randomizer",

    interval: "30",
    caption: "",
  },
  {
    id: Math.floor(Math.random() * max + 1),
    src:
      " /assets/images/thumb2/1 (" +
      Math.floor(Math.random() * max + 1) +
      ").jpg",
    altText: "Gallery Randomizer",

    interval: "30",
    caption: "",
  },
  {
    id: Math.floor(Math.random() * max + 1),
    src:
      " /assets/images/thumb2/1 (" +
      Math.floor(Math.random() * max + 1) +
      ").jpg",
    altText: "Gallery Randomizer",

    interval: "30",
    caption: "",
  },
  /* {
    id: 3,
    src: image3,
    altText: "Slide 3",
    caption: "Slide 3",
  }, */
];

const CarouselDefault = () => <UncontrolledCarousel items={items} />;

export default CarouselDefault;
